import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Alert } from 'react-bootstrap';
import { auth } from '../../firebase/firebaseConfig';

function AccountSetup({ email, userType, familyIDs, familyName, onSetupComplete }) {
  const [name, setName] = useState('');
  const [familyNames, setFamilyNames] = useState([]);
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userType === 'invitedUser') {
      setFamilyNames([familyName]);
    } else {
      setFamilyNames(familyIDs.map(() => ''));
    }
  }, [userType, familyName, familyIDs]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!userType) {
      console.error('Error: userType during AccountSetup is null');
      setMessage('An error occurred. Please try again.');
      setIsLoading(false);
      return;
    }

    if (!name || (userType === 'betaUser' && familyNames.some(f => !f))) {
      setMessage('All fields are required.');
      setIsLoading(false);
      return;
    }

    try {
      const currentUser = auth.currentUser;
      const idToken = await currentUser.getIdToken();

      const response = await fetch('https://api-4nffdszqla-uc.a.run.app/user/setup', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({ email, name, familyNames, userType, familyIDs }),
      });

      const data = await response.json();
      if (data.success) {
        onSetupComplete();
      } else {
        setMessage(data.error || 'Setup failed. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container className="mt-5" style={{ maxWidth: "400px" }}>
      <h2 className="text-center mb-4">Account Setup</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group controlId="name" className="mb-3">
          <Form.Label>Your Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter your name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Form.Group>
        {userType === 'betaUser' ? (
          familyIDs.map((id, index) => (
            <Form.Group controlId={`familyName-${index}`} className="mb-3" key={id}>
              {index === 0 ? 
                (<Form.Label>Primary Family Name {index + 1}</Form.Label>) :
                (<Form.Label>Family Name {index + 1}</Form.Label>)
              }
              <Form.Control
                type="text"
                placeholder="Enter family name"
                value={familyNames[index]}
                onChange={(e) => {
                  const newFamilyNames = [...familyNames];
                  newFamilyNames[index] = e.target.value;
                  setFamilyNames(newFamilyNames);
                }}
                required
              />
            </Form.Group>
          ))
        ) : (
          <Form.Group controlId="familyName" className="mb-3">
            <Form.Label>Invited to Family</Form.Label>
            <Form.Control
              type="text"
              placeholder="Invited family name"
              value={familyName}
              readOnly={true}
            />
          </Form.Group>
        )}
        <Button type="submit" variant="primary" disabled={isLoading}>
          {isLoading ? 'Processing...' : 'Setup'}
        </Button>
      </Form>
      {message && (
        <Alert variant="danger" className="mt-3">
          <strong>{message}</strong>
        </Alert>
      )}
    </Container>
  );
}

export default AccountSetup; 