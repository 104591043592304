// src/components/Dashboard/Controllers.js
import React, { useEffect, useState, useCallback } from "react";
import { db, auth } from "../../firebase/firebaseConfig";
import {
  collection,
  getDocs,
  setDoc,
  doc,
  deleteDoc,
  getDoc,
} from "firebase/firestore";
import { Form, Button, ListGroup, InputGroup, Container } from "react-bootstrap";

function Controllers({ familyID, onReturn }) {
  const [controllers, setControllers] = useState([]);
  const [inviteEmail, setInviteEmail] = useState("");
  const [isOnlyController, setIsOnlyController] = useState(true);

  const loadControllers = useCallback(async () => {
    const controllersList = [];
    const controllersCollectionRef = collection(
      db,
      "familyInfo",
      familyID,
      "controllers"
    );

    try {
      const snapshot = await getDocs(controllersCollectionRef);
      for (let docSnap of snapshot.docs) {
        const email = docSnap.id;
        let isPending = false;

        const pendingInviteRef = doc(
          db,
          "familyInfo",
          familyID,
          "pendingControllerInvites",
          email
        );
        const inviteDocSnap = await getDoc(pendingInviteRef);
        if (inviteDocSnap.exists()) {
          isPending = true;
        }
        controllersList.push({ email, isPending });
      }
      
      const activeControllers = controllersList.filter(c => !c.isPending);
      setIsOnlyController(activeControllers.length <= 1);
      
      setControllers(controllersList);
    } catch (error) {
      console.error("Error loading controllers:", error);
    }
  }, [familyID]);

  useEffect(() => {
    loadControllers();
  }, [loadControllers]);

  const inviteController = async () => {
    if (inviteEmail === "") {
      alert("Please enter an email address.");
      return;
    }

    try {
      // Create controller and pending invite docs (existing functionality)
      const controllersRef = doc(db, "familyInfo", familyID, "controllers", inviteEmail);
      const pendingInvitesRef = doc(db, "familyInfo", familyID, "pendingControllerInvites", inviteEmail);
      
      // New reference for the invite message
      const inviteMessageRef = doc(
        db,
        "familyInviteMessages",
        inviteEmail,
        "familyIDs",
        familyID
      );

      // Execute all writes
      await Promise.all([
        setDoc(controllersRef, {}),
        setDoc(pendingInvitesRef, {}),
        setDoc(inviteMessageRef, {
          type: "controller",
          timestamp: new Date(),
        }),
      ]);

      setInviteEmail("");
      loadControllers();
    } catch (error) {
      console.error("Error inviting controller:", error);
    }
  };

  const giveUpControl = async () => {
    const currentUserEmail = auth.currentUser.email;
    
    try {
      const individualInfoRef = doc(db, "individualInfo", currentUserEmail);
      const individualDoc = await getDoc(individualInfoRef);
      
      if (individualDoc.exists()) {
        const data = individualDoc.data();
        
        // Check if this is the primary family
        if (data.primaryFamilyID === familyID) {
          alert("You cannot give up control of your primary family.");
          return;
        }

        // Update the families array
        const updatedFamilies = data.families.filter(id => id !== familyID);
        await setDoc(individualInfoRef, { families: updatedFamilies }, { merge: true });

        // Remove controller access
        const controllersRef = doc(db, "familyInfo", familyID, "controllers", currentUserEmail);
        const pendingInvitesRef = doc(db, "familyInfo", familyID, "pendingControllerInvites", currentUserEmail);
        const inviteMessageRef = doc(db, "familyInviteMessages", currentUserEmail, "familyIDs", familyID);

        await Promise.all([
          deleteDoc(controllersRef),
          deleteDoc(pendingInvitesRef),
          deleteDoc(inviteMessageRef)
        ]);

        // Return to main view
        onReturn();
      }
    } catch (error) {
      console.error("Error giving up control:", error);
    }
  };

  return (
    <Container id="controllers-section" className="mt-4">
      <h3>Controllers</h3>
      <ListGroup className="mb-3">
        {controllers.map((controller) => (
          <ListGroup.Item key={controller.email} className="d-flex justify-content-between align-items-center">
            {controller.email} {controller.isPending && "(Pending)"}
            {controller.email === auth.currentUser.email && (
              <Button
                variant="outline-danger"
                size="sm"
                onClick={giveUpControl}
                disabled={isOnlyController}
              >
                Give Up Control
              </Button>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
      <InputGroup className="mb-3" style={{ maxWidth: "400px" }}>
        <Form.Control
          type="email"
          placeholder="Invite controller by email"
          value={inviteEmail}
          onChange={(e) => setInviteEmail(e.target.value)}
        />
        <Button variant="primary" onClick={inviteController}>
          Invite
        </Button>
      </InputGroup>
    </Container>
  );
}

export default Controllers;
