import React, { useState, useEffect } from "react";
import { Button, Container, Card, Alert } from "react-bootstrap";
import { db } from "../../firebase/firebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import { auth } from "../../firebase/firebaseConfig";
import {QRCodeSVG} from 'qrcode.react';

function DeviceSetup({ familyID, onReturn }) {
    const [familyName, setFamilyName] = useState("");
    const [familyUserID, setFamilyUserID] = useState("");
    const [selectedOption, setSelectedOption] = useState(null);
    const [wifiSSID, setWifiSSID] = useState("");
    const [wifiPassword, setWifiPassword] = useState("");
    const [message, setMessage] = useState("");
    const [passcode, setPasscode] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [qrGenerated, setQrGenerated] = useState(false);
    const [guestLink, setGuestLink] = useState("");
    const [copyStatus, setCopyStatus] = useState(false);
    const [isGeneratingLink, setIsGeneratingLink] = useState(false);
    const [isGeneratingQR, setIsGeneratingQR] = useState(false);

    useEffect(() => {
        const fetchFamilyData = async () => {
          const familyDocRef = doc(db, "familyInfo", familyID);
          setFamilyUserID(familyID + "@family.higlo.ai");
          try {
            const docSnap = await getDoc(familyDocRef);
            if (docSnap.exists()) {
              setFamilyName(docSnap.data().name);
            }
          } catch (error) {
            console.error("Error fetching family data:", error);
          }
        };
    
        fetchFamilyData();
    }, [familyID]);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setQrGenerated(false);
        setWifiSSID("");
        setWifiPassword("");
        setMessage("");
        setPasscode("");
        setGuestLink("");
        if (option === "invite") {
            handleSubmitInviteSetup();
        }
    };

    const handleSubmitSelfSetup = async () => {
        // Self setup
        setIsGeneratingQR(true);
        setPasscode("");
        if (!wifiSSID || !wifiPassword) {
            setMessage("WiFi SSID and Password cannot be empty.");
            setIsGeneratingQR(false);
            return;
        }

        try {
            const currentUser = auth.currentUser;
            const idToken = await currentUser.getIdToken();

            const response = await fetch('https://api-4nffdszqla-uc.a.run.app/user/generatePasscode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    userEmail: currentUser.email,
                    familyID,
                }),
            });

            const data = await response.json();
            if (data.success) {
                setPasscode(data.code);
                setQrGenerated(true);
            } else {
                console.error(data.error || 'Failed to generate passcode. Please try again.');
                setMessage('Failed to generate passcode. Please try again.');
                setIsGeneratingQR(false);
                return;
            }
        } catch (error) {
            console.error('Error in generatePasscode Cloud Function:', error);
            setMessage('Failed to generate passcode. Please try again.');
            setIsGeneratingQR(false);
            return;
        }
        setMessage(""); // Clear message on successful submission
        setIsGeneratingQR(false);
    };

    const handleSubmitInviteSetup = async () => {
        setIsGeneratingLink(true); // Start loading
        setGuestLink("");
        try {
            const currentUser = auth.currentUser;
            const idToken = await currentUser.getIdToken();

            const response = await fetch('https://api-4nffdszqla-uc.a.run.app/user/generatePasscode', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
                body: JSON.stringify({
                    userEmail: currentUser.email,
                    familyID,
                }),
            });

            const data = await response.json();
            if (data.success) {
                const trailerPad = "1234567890";
                let guestLink = 'https://higlo.ai/deviceSetupLocal?token=' + data.code + '&fID=' + familyID + '&fName=' + familyName + '&ts=' + data.timestamp._seconds + '&pad=' + trailerPad;
                setGuestLink(guestLink);
            } else {
                console.error(data.error || 'Failed to generate Guest link. Please try again.');
                setMessage('Failed to generate Guest link. Please try again.');
            }
        } catch (error) {
            console.error('Error in generatePasscode Cloud Function:', error);
            setMessage('Failed to generate Guest link. Please try again.');
        } finally {
            setIsGeneratingLink(false); // End loading
        }
    };

    const handleRegenerateQR = () => {
        setQrGenerated(false);
        setWifiSSID("");
        setWifiPassword("");
        setPasscode("");
    };

    return (
        <Container id="device-setup-section" className="mt-5">
            <Card className="mb-4">
                <Card.Body className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center fw-bold" style={{ flex: 1 }}>
                        <h4 className="mb-0">{familyName}</h4>
                    </div>
                    <Button 
                    variant="outline-secondary" 
                    size="sm" 
                    onClick={onReturn}
                    >
                    Return
                    </Button>
                </Card.Body>
            </Card>

            <div className="mb-4">
                <h4>Setup Higlo Device</h4>
                <p>Select a setup option to proceed:</p>

                <div className="d-flex justify-content-around mt-3" style={{ justifyContent: 'space-around' }}>
                    <div className="text-center mx-2">
                        <p className="mb-2">Set up the device yourself using your WiFi credentials</p>
                        <Button 
                            variant={selectedOption === "self" ? "primary" : "outline-primary"} 
                            onClick={() => handleOptionSelect("self")}
                        >
                            Self setup
                        </Button>
                    </div>
                    <div className="text-center mx-2">
                        <p className="mb-2">Invite a user to set up the device for this family</p>
                        <Button 
                            variant={selectedOption === "invite" ? "primary" : "outline-primary"} 
                            onClick={() => handleOptionSelect("invite")}
                        >
                            Other user setup
                        </Button>
                    </div>
                </div>

                {selectedOption === "self" && (
                    <div className="mt-3 mx-auto" style={{ width: '50%' }}>
                        {!qrGenerated ? (
                            <>
                                <p>Enter the Wifi SSID and Password to connect the device to your network</p>
                                <input 
                                    type="text" 
                                    placeholder="WiFi SSID" 
                                    value={wifiSSID} 
                                    onChange={(e) => setWifiSSID(e.target.value)} 
                                    className="form-control mb-2"
                                    style={{ width: '80%', marginBottom: '1rem' }}
                                />
                                <div className="input-group mb-2" style={{ width: '80%', marginBottom: '1rem' }}>
                                    <input 
                                        type={showPassword ? "text" : "password"} 
                                        placeholder="WiFi Password" 
                                        value={wifiPassword} 
                                        onChange={(e) => setWifiPassword(e.target.value)} 
                                        className="form-control"
                                    />
                                    <button 
                                        className="btn btn-outline-secondary" 
                                        type="button" 
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? "Hide" : "Show"}
                                    </button>
                                </div>
                                <Button 
                                    variant="outline-success" 
                                    onClick={handleSubmitSelfSetup} 
                                    className="mt-2"
                                    disabled={isGeneratingQR}
                                >
                                    {isGeneratingQR ? "Generating..." : "Submit"}
                                </Button>
                            </>
                        ) : (
                            <>
                                <p>Steps to connect the Higlo device to your network</p>
                                <ol>
                                    <li>Turn on the Higlo device.</li>
                                    <li>Show the QR code below to the device camera to connect it to the network.</li>
                                    <li>You can update the WiFi credentials and regenerate the QR code using the 'Update WiFi Credentials' button.</li>
                                    <li>This QR code is only valid for 10 minutes.</li>
                                </ol>
                                <div className="d-flex justify-content-center">
                                    <Button variant="outline-secondary" onClick={handleRegenerateQR} className="mt-2" size="sm">Update WiFi Credentials</Button>
                                </div>
                                <div className="d-flex justify-content-center mt-3 mb-3">
                                    <QRCodeSVG
                                        value={[`${wifiSSID};`, `${wifiPassword};`, `${familyUserID};`, `${passcode};`]}
                                        size={512}
                                    />
                                </div>
                            </>
                        )}
                    </div>
                )}
                {selectedOption === "invite" && (
                    <div className="mt-3 mx-auto" style={{ width: '50%' }}>
                        {isGeneratingLink ? (
                            <div className="d-flex align-items-center">
                                <div className="spinner-border spinner-border-sm text-primary me-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                                <p className="mb-0">Generating link...</p>
                            </div>
                        ) : (
                            guestLink && (
                                <>
                                    <p>Share the link below to allow another user to setup the device. This link contains confidential credentials, only share it with people you trust. The link expires in 24 hours.</p>
                                    <Card className="mt-2" style={{ width: '100%' }}>
                                        <Card.Body>
                                            <a href={guestLink} target="_blank" rel="noopener noreferrer" className="mr-3">
                                                {guestLink}
                                            </a>
                                        </Card.Body>
                                    </Card>
                                    <div className="d-flex align-items-center mt-2">
                                        <Button 
                                            variant={copyStatus ? "success" : "outline-primary"} 
                                            size="sm" 
                                            onClick={() => {
                                                navigator.clipboard.writeText(guestLink);
                                                setCopyStatus(true);
                                                setTimeout(() => setCopyStatus(false), 2000); // Reset after 2 seconds
                                            }}
                                            className="me-2"
                                            style={{ minWidth: '80px' }} // Ensures consistent width
                                        >
                                            {copyStatus ? "Copied!" : "Copy"}
                                        </Button>
                                        <Button 
                                            variant="outline-secondary" 
                                            size="sm"
                                            onClick={handleSubmitInviteSetup} 
                                        >
                                            Re-generate Link
                                        </Button>
                                    </div>
                                </>
                            )
                        )}
                    </div>
                )}

                {message && (
                    <Alert variant="danger" className="mt-3 mx-auto" style={{ width: '50%' }}>
                        <strong>{message}</strong>
                    </Alert>
                )}
                
            </div>
        </Container>
    );
}

export default DeviceSetup;