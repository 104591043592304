import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import SignIn from './components/Auth/SignIn';
import VerifyCode from './components/Auth/VerifyCode';
import AccountSetup from './components/Auth/AccountSetup';
import IndividualDashboard from './components/IndividualDashboard/IndividualDashboard';
import HomeDashboard from './components/HomeDashboard/HomeDashboard';
import DeviceSetup from './components/DeviceSetup/DeviceSetup';
import GuestDeviceSetup from './components/DeviceSetup/GuestDeviceSetup';
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase/firebaseConfig";

function App() {
  const [step, setStep] = useState('signin');
  const [email, setEmail] = useState('');
  const [userType, setUserType] = useState('');
  const [familyIDs, setFamilyIDs] = useState([]);
  const [familyName, setFamilyName] = useState('');
  const [selectedFamilyID, setSelectedFamilyID] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setIsAuthenticated(true);
        setEmail(currentUser.email);
        try {
          const idToken = await currentUser.getIdToken();
          const response = await fetch(`https://api-4nffdszqla-uc.a.run.app/user/userinfo?email=${currentUser.email}`, {
            headers: {
              'Authorization': `Bearer ${idToken}`
            }
          });
          const data = await response.json();
          console.log(data);
          if (data.needsSetup) {
            setUserType(data.userType);
            setFamilyIDs(data.familyIDs);
            setFamilyName(data.familyName);
            setStep('setup');
          } else {
            setStep('dashboard');
          }
        } catch (error) {
          console.error('Error fetching user info:', error);
        }
      } else {
        setIsAuthenticated(false);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleEmailSubmit = (email) => {
    setEmail(email);
    setStep('verify');
  };

  const handleVerificationSuccess = () => {
    setIsAuthenticated(true);
    setStep('setup');
  };

  const handleSetupComplete = () => {
    setStep('dashboard');
  };

  const handleManageFamily = (familyID) => {
    setSelectedFamilyID(familyID);
    setStep('home');
  };

  const handleSetupDevice = (familyID) => {
    setSelectedFamilyID(familyID);
    setStep('deviceSetup');
  };

  const handleReturn = () => {
    setStep('dashboard');
  };

  return (
    <Router>
      <Routes>
        <Route path="/deviceSetupLocal" element={!isAuthenticated && <GuestDeviceSetup />} />
        <Route path="/" element={
          step === 'signin' ? <SignIn onEmailSubmit={handleEmailSubmit} /> :
          step === 'verify' ? <VerifyCode email={email} onVerificationSuccess={handleVerificationSuccess} /> :
          step === 'setup' ? <AccountSetup email={email} userType={userType} familyIDs={familyIDs} familyName={familyName} onSetupComplete={handleSetupComplete} /> :
          step === 'dashboard' ? <IndividualDashboard onManageFamily={handleManageFamily} onSetupDevice={handleSetupDevice} /> :
          step === 'home' ? <HomeDashboard familyID={selectedFamilyID} onReturn={handleReturn} /> :
          step === 'deviceSetup' ? <DeviceSetup familyID={selectedFamilyID} onReturn={handleReturn} /> :
          <Navigate to="/" />
        } />
      </Routes>
    </Router>
  );
}

export default App;
