import React, { useState, useEffect } from "react";
import { Button, Container, Card, Alert } from "react-bootstrap";
import {QRCodeSVG} from 'qrcode.react';

function GuestDeviceSetup() {
    const [familyName, setFamilyName] = useState("");
    const [familyUserID, setFamilyUserID] = useState("");
    const [passcode, setPasscode] = useState("");
    const [wifiSSID, setWifiSSID] = useState("");
    const [wifiPassword, setWifiPassword] = useState("");
    const [message, setMessage] = useState("");
    const [qrGenerated, setQrGenerated] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const familyID = urlParams.get('fID');
        const familyName = urlParams.get('fName');
        const timestamp = urlParams.get('ts');
        const pad = urlParams.get('pad');

        if (!token || !familyID || !familyName || !timestamp || !pad) {
            setMessage("The link you've entered is either incorrect or incomplete. Please check the link you received or ask your contact to send you a new link.");
            return;
        }

        const currentTime = Math.floor(Date.now() / 1000);
        if (currentTime - parseInt(timestamp) > 86400) { // 24 hours in seconds
            setMessage("The link is only valid for 24 hours and has expired. Please ask your contact to send you a new link.");
            return;
        }

        setFamilyName(familyName);
        setFamilyUserID(familyID + "@family.higlo.ai");
        setPasscode(token);
        setIsVerified(true);
    }, []);

    const handleSubmit = () => {
        if (!wifiSSID || !wifiPassword) {
            setMessage("WiFi SSID and Password fields cannot be empty.");
            return;
        }
        setQrGenerated(true);
        setMessage(""); // Clear message on successful submission
    };

    const handleRegenerateQR = () => {
        setQrGenerated(false);
        setWifiSSID("");
        setWifiPassword("");
    };

    return (
        <Container id="guest-device-setup-section" className="mt-5">
            <Card className="mb-4">
                <Card.Body className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center fw-bold" style={{ flex: 1 }}>
                        <h4 className="mb-0">{familyName}</h4>
                    </div>
                </Card.Body>
            </Card>

            <div className="mb-4">
                <h4>Setup Higlo Device</h4>
            </div>

            <div className="mt-3 mx-auto" style={{ width: '50%' }}>
                {isVerified && !qrGenerated && (
                    <>
                        <p>Enter the Wifi SSID and Password to connect the device to your network</p>
                        <input 
                            type="text" 
                            placeholder="WiFi SSID" 
                            value={wifiSSID} 
                            onChange={(e) => setWifiSSID(e.target.value)} 
                            className="form-control mb-2"
                            style={{ width: '80%', marginBottom: '1rem' }}
                        />
                        <div className="input-group mb-2" style={{ width: '80%', marginBottom: '1rem' }}>
                            <input 
                                type={showPassword ? "text" : "password"} 
                                placeholder="WiFi Password" 
                                value={wifiPassword} 
                                onChange={(e) => setWifiPassword(e.target.value)} 
                                className="form-control"
                            />
                            <button 
                                className="btn btn-outline-secondary" 
                                type="button" 
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                {showPassword ? "Hide" : "Show"}
                            </button>
                        </div>
                        <Button variant="outline-success" onClick={handleSubmit} className="mt-2">Submit</Button>
                    </>
                )}

                {isVerified && qrGenerated && (
                    <>
                        <p>Steps to connect the Higlo device to your network</p>
                        <ol>
                            <li>Turn on the Higlo device.</li>
                            <li>Show the QR code below to the device camera to connect it to the network.</li>
                            <li>You can update the WiFi credentials and regenerate the QR code using the 'Update WiFi Credentials' button.</li>
                            <li>This QR code is only valid for 10 minutes.</li>
                        </ol>
                        <div className="d-flex justify-content-center">
                            <Button variant="outline-secondary" onClick={handleRegenerateQR} className="mt-2" size="sm">Update WiFi Credentials</Button>
                        </div>
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            <QRCodeSVG
                                value={[`${wifiSSID};`, `${wifiPassword};`, `${familyUserID};`, `${passcode};`]}
                                size={512}
                            />
                        </div>
                    </>
                )}

                {message && (
                    <Alert variant="danger" className="mt-3">
                        <strong>{message}</strong>
                    </Alert>
                )}
            </div>
        </Container>
    );
}

export default GuestDeviceSetup;