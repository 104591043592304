// src/components/Dashboard/IglooMembers.js
import React, { useEffect, useState } from "react";
import ContentEditable from "react-contenteditable";
import { db } from "../../firebase/firebaseConfig";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  deleteDoc,
  getDocs,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { Button, Form, ListGroup } from "react-bootstrap";
import { auth } from "../../firebase/firebaseConfig";

function IglooMembers({ iglooID, familyID, isInviteView = false, onUpdate }) {
  const [members, setMembers] = useState([]);
  const [familyNames, setFamilyNames] = useState({});
  const [isController, setIsController] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [invitePhoneNumber, setInvitePhoneNumber] = useState("");
  const [inviteKnownName, setInviteKnownName] = useState("");
  const [inviteRole, setInviteRole] = useState("member");
  const [editingFamilyID, setEditingFamilyID] = useState(null);
  const [tempName, setTempName] = useState("");
  const [inviteType, setInviteType] = useState("familyName");
  const [connectedFamilies, setConnectedFamilies] = useState([]);

  useEffect(() => {
    checkIfController();
    loadIglooMembers();
    loadConnectedFamilies();
  }, [iglooID]);

  const removeFamilyFromIgloo = async (familyIDToRemove) => {
    try {
      await deleteDoc(doc(db, "iglooInfo", iglooID, "pending", familyIDToRemove));
      await deleteDoc(doc(db, "iglooInviteMessage", familyIDToRemove, "iglooIDs", iglooID));

      const iglooDocRef = doc(db, "iglooInfo", iglooID);
      const docSnap = await getDoc(iglooDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const controllers = (data.controllers || []).filter(id => id !== familyIDToRemove);
        const members = (data.members || []).filter(id => id !== familyIDToRemove);
        await setDoc(iglooDocRef, { controllers, members }, { merge: true });
      }

      await deleteDoc(doc(db, "familyInfo", familyIDToRemove, "igloos", iglooID));
      await deleteDoc(doc(db, "familyInfo", familyIDToRemove, "activeIgloos", iglooID));
    } catch (error) {
      console.error("Error removing family from igloo:", error);
    }
  };

  const checkIfController = async () => {
    const iglooDocRef = doc(db, "iglooInfo", iglooID);
    try {
      const docSnap = await getDoc(iglooDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setIsController((data.controllers || []).includes(familyID));
      }
    } catch (error) {
      console.error("Error checking controller status:", error);
    }
  };

  const loadIglooMembers = () => {
    let membersList = [];
    let controllersList = [];
    const iglooDocRef = doc(db, "iglooInfo", iglooID);
    const iglooPendingRef = collection(db, "iglooInfo", iglooID, "pending");

    const checkPendingStatus = async (memberFamilyID) => {
      const pendingDocRef = doc(iglooPendingRef, memberFamilyID);
      const pendingDocSnap = await getDoc(pendingDocRef);
      return pendingDocSnap.exists();
    };

    const iglooDocSnapshotHandler = async (docSnap) => {
      if (docSnap.exists()) {
        const data = docSnap.data();
        const controllersArray = data.controllers || [];
        const membersArray = data.members || [];

        controllersList = await Promise.all(
          controllersArray.map(async (memberFamilyID) => {
            const isPendingController = await checkPendingStatus(memberFamilyID);
            await loadFamilyName(memberFamilyID);
            return {
              familyID: memberFamilyID,
              role: "controller",
              isPending: isPendingController,
            };
          })
        );

        membersList = await Promise.all(
          membersArray.map(async (memberFamilyID) => {
            const isPendingMember = await checkPendingStatus(memberFamilyID);
            await loadFamilyName(memberFamilyID);
            return {
              familyID: memberFamilyID,
              role: "member",
              isPending: isPendingMember,
            };
          })
        );

        setMembers([...controllersList, ...membersList]);
      }
    };
    const iglooDocErrorHandler = (error) => {
      console.error("Error in iglooDoc snapshot handler:", error);
    };
    onSnapshot(iglooDocRef, iglooDocSnapshotHandler, iglooDocErrorHandler);

    const pendingDocSnapshotHandler = async () => {
      const updatedControllersList = await Promise.all(
        controllersList.map(async (member) => ({
          ...member,
          isPending: await checkPendingStatus(member.familyID),
        }))
      );
      const updatedMembersList = await Promise.all(
        membersList.map(async (member) => ({
          ...member,
          isPending: await checkPendingStatus(member.familyID),
        }))
      );
      controllersList = updatedControllersList;
      membersList = updatedMembersList;
      setMembers([...controllersList, ...membersList]);
    }
    const pendingDocErrorHandler = (error) => {
      console.error("Error in igloo pendingDoc snapshot handler:", error);
    };
    onSnapshot(iglooPendingRef, pendingDocSnapshotHandler, pendingDocErrorHandler);

    // TODO: We need to stop the snapshot listeners above, before deleting an igloo or any of its documents.
    // If not, we are trying to read a deleted document without the required permissions, and it throws an error.
    // Example scenario: A family is invited to an igloo as a member and they decline the invitation.
    // The pending doc is deleted an the family is removed from the members array of the igloo, but the snapshot listener still tries to read the deleted document, and throws an error.
  };

  const loadFamilyName = async (memberFamilyID) => {
    if (!familyNames[memberFamilyID]) {
      const name = await getFamilyDisplayName(memberFamilyID);
      setFamilyNames((prevNames) => ({
        ...prevNames,
        [memberFamilyID]: name,
      }));
    }
  };

  const getFamilyDisplayName = async (targetFamilyID) => {
    let connectedFamilyRef;
    if (familyID === targetFamilyID) {
      connectedFamilyRef = doc(db, "familyInfo", familyID);
    } else {
      connectedFamilyRef = doc(
        db,
        "familyInfo",
        familyID,
        "connectedFamilies",
        targetFamilyID
      );
    }

    try {
      const docSnap = await getDoc(connectedFamilyRef);
      if (docSnap.exists()) {
        return docSnap.data().name;
      } else {
        const familyDocRef = doc(db, "familyInfo", targetFamilyID);
        const familyDocSnap = await getDoc(familyDocRef);
        if (familyDocSnap.exists()) {
          return familyDocSnap.data().name;
        } else {
          return "Unknown Family";
        }
      }
    } catch (error) {
      console.error("Error getting family display name:", error);
      return "Unknown Family";
    }
  };

  const updateFamilyName = async (memberFamilyID, newName) => {
    const connectedFamilyRef = doc(
      db,
      "familyInfo",
      familyID,
      "connectedFamilies",
      memberFamilyID
    );

    if(connectedFamilies.find((family) => family.name === newName.trim())) {
      alert("A different family is already known by this name, please choose another.");
      return;
    }
    try {
      await setDoc(connectedFamilyRef, { name: newName }, { merge: true });
      setFamilyNames((prevNames) => ({
        ...prevNames,
        [memberFamilyID]: newName,
      }));
    } catch (error) {
      console.error("Error updating family name:", error);
      alert(`Error updating family name: ${error}`);
    }
  };

  const handleNameChange = (event) => {
    setTempName(event.target.value);
  };

  const startEditing = (memberFamilyID) => {
    setEditingFamilyID(memberFamilyID);
    setTempName(familyNames[memberFamilyID]);
  };

  const saveNameChange = (memberFamilyID) => {
    updateFamilyName(memberFamilyID, tempName);
    setEditingFamilyID(null);
  };

  const cancelNameChange = () => {
    setEditingFamilyID(null);
  };

  const loadConnectedFamilies = async () => {
    const connectedFamiliesRef = collection(
      db,
      "familyInfo",
      familyID,
      "connectedFamilies"
    );
    const snapshot = await getDocs(connectedFamiliesRef);
    const families = snapshot.docs.map((doc) => ({
      id: doc.id,
      name: doc.data().name,
    }));
    setConnectedFamilies(families);
  };

  const inviteFamilyToIgloo = async () => {
    let inviteeFamilyID = null;
    try {
      if (inviteType === "email") {
        if (inviteEmail === "") {
          alert("Please enter an email.");
          return;
        }
        if (inviteKnownName.trim() === "") {
          alert("Please enter a name for this family.");
          return;
        }
        inviteeFamilyID = await getFamilyIDByEmail(inviteEmail);
      } else if (inviteType === "phoneNumber") {
        if (invitePhoneNumber === "") {
          alert("Please enter a phone number.");
          return;
        }
        if (inviteKnownName.trim() === "") {
          alert("Please enter a name for this family.");
          return;
        }
        inviteeFamilyID = await getFamilyIDByPhoneNumber(invitePhoneNumber);
      } else if (inviteType === "familyName") {
        if (!inviteKnownName) {
          alert("Please select a family name.");
          return;
        }
        inviteeFamilyID = connectedFamilies.find((family) => family.name === inviteKnownName)?.id;
      }

      if (!inviteeFamilyID) {
        console.error("Error: inviteeFamilyID is null or undefined.");
        alert("The provided information is not associated with any family in the network.");
        return;
      }

      const pendingRef = doc(db, "iglooInfo", iglooID, "pending", inviteeFamilyID);
      const pendingRefSnapshot = await getDoc(pendingRef);
      if(pendingRefSnapshot.exists()) {
        console.warn("Family invite already exists.");
        alert("This family has already been invited to this igloo.");
        return;
      }
      
      const iglooDocRef = doc(db, "iglooInfo", iglooID);
      const iglooSnapshot = await getDoc(iglooDocRef);
      if(!iglooSnapshot.exists()) {
        const msg = "There was an internal error inviting the family to the igloo. The igloo document does not exits. Please report this.";
        console.error(msg);
        alert(msg);
        return;
      }
      if(iglooSnapshot.data().members.includes(inviteeFamilyID)) {
        console.warn("Family is already in the members list for this igloo.");
        alert("The invited family is already a member of this igloo.");
        return;
      }
      if(iglooSnapshot.data().controllers.includes(inviteeFamilyID)) {
        console.warn("Family is already in the controllers list for this igloo.");
        alert("The invited family is already a controller of this igloo.");
        return;
      }

      if (
        inviteType !== "familyName"
        && connectedFamilies.find((family) => family.name === inviteKnownName.trim())
      ) {
        const msg = "A connected family is already known by this name, please choose another name for the family.";
        console.warn(msg);
        alert(msg);
        return;
      }

      await setDoc(pendingRef, { from: familyID, role: inviteRole });
      if (inviteRole === "controller") {
        await updateDoc(iglooDocRef, { controllers: arrayUnion(inviteeFamilyID) });
      } else {
        await updateDoc(iglooDocRef, { members: arrayUnion(inviteeFamilyID) });
      }
      const inviteMessageRef = doc(db, "iglooInviteMessage", inviteeFamilyID, "iglooIDs", iglooID);
      await setDoc(inviteMessageRef, {});
      if (inviteType !== "familyName") {
        const connectedFamilyRef = doc(db, "familyInfo", familyID, "connectedFamilies", inviteeFamilyID);
        await setDoc(connectedFamilyRef, { name: inviteKnownName });
      }

      console.log("Invite sent successfully!");
      setInviteEmail("");
      setInvitePhoneNumber("");
      setInviteKnownName("");
      onUpdate?.();
    } catch (error) {
      console.error("Error inviting family to igloo:", error);
    }
  };

  const getFamilyIDByEmail = async (email) => {
    const individualDocRef = doc(db, "individualInfo", email);
    try {
      const docSnap = await getDoc(individualDocRef);
      if (docSnap.exists()) {
        return docSnap.data().primaryFamilyID;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting familyID by email:", error);
      return null;
    }
  };

  const getFamilyIDByPhoneNumber = async (phoneNumber) => {
    const phoneLookupRef = doc(db, "phoneNumberLookup", phoneNumber);
    try {
      const docSnap = await getDoc(phoneLookupRef);
      if (docSnap.exists()) {
        return docSnap.data().familyID;
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error getting familyID by phone number:", error);
      return null;
    }
  };

  const changeMemberRole = async (memberFamilyID, newRole) => {
    const iglooDocRef = doc(db, "iglooInfo", iglooID);

    try {
      const docSnap = await getDoc(iglooDocRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        let controllers = data.controllers || [];
        let members = data.members || [];

        if (newRole === "controller") {
          members = members.filter(id => id !== memberFamilyID);
          if (!controllers.includes(memberFamilyID)) {
            controllers.push(memberFamilyID);
          }
        } else {
          controllers = controllers.filter(id => id !== memberFamilyID);
          if (!members.includes(memberFamilyID)) {
            members.push(memberFamilyID);
          }
        }

        await setDoc(iglooDocRef, { controllers, members }, { merge: true });
      }
    } catch (error) {
      console.error("Error changing member role:", error);
    }
  };

  const canLeaveAsController = () => {
    // Filter out pending controllers
    const activeControllers = members.filter(
      member => member.role === "controller" && !member.isPending
    );
    // Check if there are other active controllers besides the current user
    return activeControllers.length > 1;
  };

  const handleMemberAction = async (memberFamilyID, isCurrentUser) => {
    try {
      const currentUser = auth.currentUser;
      const idToken = await currentUser.getIdToken();

      const response = await fetch('https://api-4nffdszqla-uc.a.run.app/user/removeMemberFromIgloo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({
          iglooID,
          iglooFamilyID: familyID,
          memberFamilyID
        }),
      });

      const data = await response.json();
      if (data.success) {
        onUpdate?.();
      } else {
        console.error('Error:', data.error || 'Failed to remove member.');
      }
    } catch (error) {
      console.error('Error handling member action:', error);
    }
  };

  return (
    <div>
      <ListGroup 
        id={`igloo-${iglooID}-members`} 
        className="mb-3 border-top border-bottom" 
        style={{ borderLeft: 'none', borderRight: 'none', borderRadius: 0}}
      >
        {members.map((member) => {
          const isCurrentUser = member.familyID === familyID;
          const isUserController = member.role === "controller";
          
          // Determine if the leave/remove button should be enabled
          const buttonDisabled = isCurrentUser
            ? (isUserController && !canLeaveAsController()) // Disable leave for sole controller
            : (!isController); // Disable remove if not a controller

          return (
            <ListGroup.Item
              key={member.familyID}
              className="d-flex justify-content-between align-items-center border-start-0 border-end-0"
              style={{ borderRadius: 0 }}
            >
              <div className="d-flex align-items-center" style={{ width: "100%" }}>
                <div style={{ flex: 2, display: "flex", alignItems: "center" }}>
                  {isCurrentUser ? (
                    <span>
                      {familyNames[member.familyID] || "Loading..."} (You)
                    </span>
                  ) : (
                    editingFamilyID === member.familyID ? (
                      <ContentEditable
                        html={tempName}
                        onChange={handleNameChange}
                        tagName="span"
                        className="editable-family-name"
                      />
                    ) : (
                      <span onClick={() => !isInviteView && startEditing(member.familyID)}>
                        {familyNames[member.familyID] || "Loading..."}
                      </span>
                    )
                  )}
                  {editingFamilyID === member.familyID && !isInviteView && (
                    <div className="ms-2">
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => saveNameChange(member.familyID)}
                        className="me-2"
                      >
                        Save
                      </Button>
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={cancelNameChange}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  {member.isPending && "(Pending)"}
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  {!isInviteView && (
                    <>
                      <Button
                        variant={isCurrentUser ? "outline-primary" : "outline-danger"}
                        size="sm"
                        onClick={() => handleMemberAction(member.familyID, isCurrentUser)}
                        className="me-2"
                        disabled={buttonDisabled}
                      >
                        {isCurrentUser ? "Leave" : "Remove"}
                      </Button>
                      </>
                    )}
                      <Form.Select
                        value={member.role}
                        onChange={(e) =>
                          changeMemberRole(member.familyID, e.target.value)
                        }
                        size="sm"
                        className="d-inline-block"
                        style={{ width: "auto" }}
                        disabled={!isController || member.familyID === familyID}
                      >
                        <option value="member">Member</option>
                        <option value="controller">Controller</option>
                      </Form.Select>
                    
                  
                </div>
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
      {isController && !isInviteView && (
        <div className="d-flex align-items-center mb-3" style={{ paddingLeft: "15px", paddingRight: "15px" }}>
          <Form.Select
            value={inviteType}
            onChange={(e) => setInviteType(e.target.value)}
            className="me-2"
          >
            <option value="familyName">Invite by Family Name</option>
            <option value="email">Invite by Email</option>
            <option value="phoneNumber">Invite by Phone Number</option>
          </Form.Select>
          {inviteType === "email" && (
            <Form.Control
              type="text"
              placeholder="Enter email"
              value={inviteEmail}
              onChange={(e) => setInviteEmail(e.target.value)}
              className="me-2"
            />
          )}
          {inviteType === "phoneNumber" && (
            <Form.Control
              type="text"
              placeholder="Enter phone number"
              value={invitePhoneNumber}
              onChange={(e) => setInvitePhoneNumber(e.target.value)}
              className="me-2"
            />
          )}
          {inviteType === "familyName" && (
            <Form.Select
              value={inviteKnownName}
              onChange={(e) => setInviteKnownName(e.target.value)}
              className="me-2"
            >
              <option value="">Select Family Name</option>
              {connectedFamilies.map((family) => (
                <option key={family.id} value={family.name}>
                  {family.name}
                </option>
              ))}
            </Form.Select>
          )}
          {inviteType !== "familyName" && (
            <Form.Control
              type="text"
              placeholder="What name do you know this family by?"
              value={inviteKnownName}
              onChange={(e) => setInviteKnownName(e.target.value)}
              className="me-2"
            />
          )}
          <Button variant="primary" onClick={inviteFamilyToIgloo} className="me-2">
            Invite
          </Button>
          <Form.Select
            value={inviteRole}
            onChange={(e) => setInviteRole(e.target.value)}
            className="d-inline-block"
            style={{ width: "auto" }}
          >
            <option value="member">Member</option>
            <option value="controller">Controller</option>
          </Form.Select>
        </div>
      )}
    </div>
  );
}

export default IglooMembers;
