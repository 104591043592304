// src/components/Dashboard/Igloos.js
import React, { useEffect, useState } from "react";
import { db } from "../../firebase/firebaseConfig";
import {
  collection,
  getDocs,
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import IglooMembers from "./IglooMembers";
import { Button, Card, Container, Row, Col, Form, InputGroup, ListGroup } from "react-bootstrap";
import ContentEditable from "react-contenteditable";
import { auth } from '../../firebase/firebaseConfig';

function Igloos({ familyID }) {
  const [igloos, setIgloos] = useState([]);
  const [activeIgloos, setActiveIgloos] = useState(new Set());
  const [editingIglooID, setEditingIglooID] = useState(null);
  const [iglooName, setIglooName] = useState("");
  const [newIglooName, setNewIglooName] = useState("");

  const [invites, setInvites] = useState([]);
  const [familyNames, setFamilyNames] = useState({});

  const [reloadTrigger, setReloadTrigger] = useState(0);

  useEffect(() => {
    loadIgloos();
    loadActiveIgloos();
    loadPendingIglooInvites();
  }, [familyID, reloadTrigger]);

  // Load igloos associated with the family
  const loadIgloos = async () => {
    const igloosList = [];
    const igloosCollectionRef = collection(db, "familyInfo", familyID, "igloos");
    try {
      const snapshot = await getDocs(igloosCollectionRef);
      for (let docSnap of snapshot.docs) {
        const iglooID = docSnap.id;
        const iglooData = docSnap.data();
        const iglooName = iglooData.name;
        const position = iglooData.position || "Inactive";
        const isController = await checkIfController(iglooID);
        igloosList.push({ iglooID, iglooName, position, isController });
      }
      setIgloos(igloosList);
    } catch (error) {
      console.error("Error loading igloos:", error);
    }
  };

  const checkIfController = async (iglooID) => {
    const iglooRef = doc(db, "iglooInfo", iglooID);
    try {
      const docSnap = await getDoc(iglooRef);
      if (docSnap.exists()) {
        const controllers = docSnap.data().controllers || [];
        return controllers.includes(familyID);
      }
      return false;
    } catch (error) {
      console.error("Error checking controller status:", error);
      return false;
    }
  };

  const loadActiveIgloos = async () => {
    const activeIgloosSet = new Set();
    const activeIgloosCollectionRef = collection(
      db,
      "familyInfo",
      familyID,
      "activeIgloos"
    );
    try {
      const snapshot = await getDocs(activeIgloosCollectionRef);
      snapshot.docs.forEach((docSnap) => {
        activeIgloosSet.add(docSnap.id);
      });
      setActiveIgloos(activeIgloosSet);
    } catch (error) {
      console.error("Error loading active igloos:", error);
    }
  };

  const toggleIglooStatus = async (iglooID) => {
    const isCurrentlyActive = activeIgloos.has(iglooID);
    const activeIgloosCollectionRef = collection(
      db,
      "familyInfo",
      familyID,
      "activeIgloos"
    );

    if (!isCurrentlyActive && activeIgloos.size >= 3) {
      alert("You're already at the limit of 3 active igloos. Please deactivate one before activating another.");
      return;
    }

    try {
      if (isCurrentlyActive) {
        await deleteDoc(doc(activeIgloosCollectionRef, iglooID));
        activeIgloos.delete(iglooID);
      } else {
        await setDoc(doc(activeIgloosCollectionRef, iglooID), {});
        activeIgloos.add(iglooID);
      }
      setActiveIgloos(new Set(activeIgloos)); // Update state with a new Set to trigger re-render
    } catch (error) {
      console.error("Error toggling igloo status:", error);
    }
  };

  // Remove family from an igloo
  const removeFamilyFromIgloo = async (iglooID) => {
    const iglooRef = doc(db, "iglooInfo", iglooID);
    try {
      const docSnap = await getDoc(iglooRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const updatedControllers = (data.controllers || []).filter(id => id !== familyID);
        const updatedMembers = (data.members || []).filter(id => id !== familyID);

        await updateDoc(iglooRef, {
          controllers: updatedControllers,
          members: updatedMembers
        });
        loadIgloos();
      }
    } catch (error) {
      console.error("Error removing family from igloo:", error);
    }
  };

  // Create a new igloo
  const createNewIgloo = async () => {
    if (!newIglooName || !newIglooName.trim()) {
      alert("The new igloo name cannot be blank.");
      return;
    }

    const currentUser = auth.currentUser;
    const idToken = await currentUser.getIdToken();

    try {
      const response = await fetch('https://api-4nffdszqla-uc.a.run.app/user/createIgloo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({ familyID, newIglooName }),
      });
      const data = await response.json();
      if (data.success) {
        setNewIglooName("");
      } else {
        alert('Create Igloo failed, please try again.');
      }
      loadIgloos();
    } catch (error) {
    console.error("Error creating new igloo:", error);
      alert('Create Igloo failed, please try again.');
    }
  };

  const handleEditToggle = (iglooID, currentName) => {
    setEditingIglooID(iglooID);
    setIglooName(currentName);
  };

  const handleSave = async (iglooID) => {
    const iglooRef = doc(db, "familyInfo", familyID, "igloos", iglooID);
    try {
      await updateDoc(iglooRef, { name: iglooName });
      setEditingIglooID(null);
      loadIgloos();
    } catch (error) {
      console.error("Error updating igloo name:", error);
    }
  };

  const handleCancel = () => {
    setEditingIglooID(null);
  };

  const handleChange = (evt) => {
    setIglooName(evt.target.value);
  };

  const handleRemoveIgloo = async (iglooID) => {
    const igloo = igloos.find((igloo) => igloo.iglooID === iglooID);
    if (igloo.position !== "Inactive") {
      alert("Only inactive igloos can be removed.");
      return;
    }

    try {
      const iglooRef = doc(db, "iglooInfo", iglooID);
      const docSnap = await getDoc(iglooRef);
      if (!docSnap.exists()) {
        return;
      }

      const docData = docSnap.data();
      const otherMembers = (docData.members || []).filter(id => id !== familyID);

      if (otherMembers.length > 0) {
        alert("You can only remove an igloo if there are no members apart from you.");
        return;
      }
        
      const currentUser = auth.currentUser;
      const idToken = await currentUser.getIdToken();

      const response = await fetch('https://api-4nffdszqla-uc.a.run.app/user/deleteIgloo', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${idToken}`
        },
        body: JSON.stringify({ familyID, iglooID }),
      });

      const data = await response.json();
      if (!data.success) {
        alert("Igloo deletion failed. Please try again.");
      }
      loadIgloos();
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }
  };

  const loadPendingIglooInvites = async () => {
    const invitesList = [];
    const invitesCollectionRef = collection(
      db,
      "iglooInviteMessage",
      familyID,
      "iglooIDs"
    );
    try {
      const snapshot = await getDocs(invitesCollectionRef);
      for (let docSnap of snapshot.docs) {
        const iglooID = docSnap.id;
        const pendingRef = doc(db, "iglooInfo", iglooID, "pending", familyID);
        const pendingDocSnap = await getDoc(pendingRef);
        const fromFamilyID = pendingDocSnap.data()?.from;

        if (fromFamilyID) {
          const familyName = await getFamilyDisplayName(fromFamilyID);
          invitesList.push({ iglooID, familyName });
        }
      }
      setInvites(invitesList);
    } catch (error) {
      console.error("Error loading pending igloo invites:", error);
    }
  };

  const getFamilyDisplayName = async (targetFamilyID) => {
    if (familyNames[targetFamilyID]) {
      return familyNames[targetFamilyID];
    }

    let connectedFamilyRef = doc(
      db,
      "familyInfo",
      familyID,
      "connectedFamilies",
      targetFamilyID
    );

    try {
      let docSnap = await getDoc(connectedFamilyRef);
      if (!docSnap.exists()) {
        connectedFamilyRef = doc(db, "familyInfo", targetFamilyID);
        docSnap = await getDoc(connectedFamilyRef);
      }

      if (docSnap.exists()) {
        const name = docSnap.data().name;
        setFamilyNames((prevNames) => ({
          ...prevNames,
          [targetFamilyID]: name,
        }));
        return name;
      } else {
        return "Unknown Family";
      }
    } catch (error) {
      console.error("Error getting family display name:", error);
      return "Unknown Family";
    }
  };

  const acceptIglooInvite = async (iglooID, familyName) => {
    try {
      // Remove from pending
      const pendingRef = doc(db, "iglooInfo", iglooID, "pending", familyID);
      await deleteDoc(pendingRef);

      const inviteMessageRef = doc(db, "iglooInviteMessage", familyID, "iglooIDs", iglooID);
      await deleteDoc(inviteMessageRef);

      const familyIgloosRef = doc(db, "familyInfo", familyID, "igloos", iglooID);
      const iglooName = `NewIglooFrom${familyName}`;
      await setDoc(familyIgloosRef, { name: iglooName });

      loadPendingIglooInvites();
      triggerReload();
    } catch (error) {
      console.error("Error accepting igloo invite:", error);
    }
  };

  const declineIglooInvite = async (iglooID) => {
    const iglooRef = doc(db, "iglooInfo", iglooID);
    const pendingRef = doc(db, "iglooInfo", iglooID, "pending", familyID);
    const inviteMessageRef = doc(db, "iglooInviteMessage", familyID, "iglooIDs", iglooID);

    try {
      await deleteDoc(pendingRef);
    } catch (error) {
      console.error("Error in deleting pending ref:", error);
      return;
    }

    try {
      await deleteDoc(inviteMessageRef);
    } catch (error) {
      console.error("Error in deleting invite message ref:", error);
      return;
    }

    try {
      const docSnap = await getDoc(iglooRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        const updatedMembers = (data.members || []).filter(id => id !== familyID);
        const updatedControllers = (data.controllers || []).filter(id => id !== familyID);

        await updateDoc(iglooRef, {
          members: updatedMembers,
          controllers: updatedControllers
        });
      }
    } catch (error) {
      console.error("Error in updating igloo ref:", error);
      return;
    }

    loadPendingIglooInvites();
  };

  const triggerReload = () => {
    setReloadTrigger(prev => prev + 1);
  };

  const toggleIglooPosition = async (iglooID, newPosition) => {
    const iglooRef = doc(db, "familyInfo", familyID, "igloos", iglooID);
    try {
      // Update the position in Firestore
      await updateDoc(iglooRef, { position: newPosition === "Inactive" ? deleteField() : newPosition });
      loadIgloos(); // Reload igloos to update the UI
    } catch (error) {
      console.error("Error updating igloo position:", error);
    }
  };

  return (
    <Container id="igloos-section" className="mt-4">
      <h3>Igloos</h3>
      <Row id="igloos-list">
        {igloos.map((igloo) => (
          <Col key={igloo.iglooID} xs={12} className="mb-3">
            <Card>
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center fw-bold" style={{ flex: 1 }}>
                  <ContentEditable
                    html={editingIglooID === igloo.iglooID ? iglooName : igloo.iglooName}
                    disabled={editingIglooID !== igloo.iglooID}
                    onChange={handleChange}
                    tagName="span"
                    className={editingIglooID === igloo.iglooID ? "editable" : ""}
                    onClick={() => handleEditToggle(igloo.iglooID, igloo.iglooName)}
                  />
                  {editingIglooID === igloo.iglooID && (
                    <div className="ms-2">
                      <Button variant="success" size="sm" onClick={() => handleSave(igloo.iglooID)} className="me-2">
                        Save
                      </Button>
                      <Button variant="secondary" size="sm" onClick={handleCancel}>
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  <span className="me-2">Position on Home:</span>
                  <Form.Select
                    value={igloo.position}
                    onChange={(e) => toggleIglooPosition(igloo.iglooID, e.target.value)}
                    size="sm"
                    className="d-inline-block"
                    style={{ width: "auto" }}
                  >
                    <option value="Inactive">Inactive</option>
                    <option value="Left" disabled={igloos.some((igloo) => igloo.position === "Left")}>Left</option>
                    <option value="Centre" disabled={igloos.some((igloo) => igloo.position === "Centre")}>Centre</option>
                    <option value="Right" disabled={igloos.some((igloo) => igloo.position === "Right")}>Right</option>
                  </Form.Select>
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => handleRemoveIgloo(igloo.iglooID)}
                    disabled={!igloo.isController}
                    className="ms-2"
                  >
                    Remove
                  </Button>
                </div>
              </Card.Body>
              <IglooMembers
                iglooID={igloo.iglooID}
                familyID={familyID}
                isInviteView={false}
                onUpdate={triggerReload}
              />
            </Card>
          </Col>
        ))}
      </Row>
      
      <Row id="igloos-invites">
        {invites.map((invite) => (
          <Col key={invite.iglooID} xs={12} className="mb-3">
            <Card>
              <Card.Body className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center fw-bold" style={{ flex: 1 }}>
                  <span>Igloo invite from {invite.familyName}</span>
                </div>
                <div style={{ flex: 1, textAlign: "right" }}>
                  <Button
                    variant="outline-success"
                    size="sm"
                    onClick={() => acceptIglooInvite(invite.iglooID, invite.familyName)}
                    className="me-2"
                  >
                    Accept
                  </Button>
                  <Button
                    variant="outline-danger"
                    size="sm"
                    onClick={() => declineIglooInvite(invite.iglooID)}
                  >
                    Decline
                  </Button>
                </div>
              </Card.Body>
              <IglooMembers
                iglooID={invite.iglooID}
                familyID={familyID}
                isInviteView={true}
              />
            </Card>
          </Col>
        ))}
      </Row>
      
      <InputGroup className="mb-3" style={{ maxWidth: "400px" }}>
        <Form.Control
          type="text"
          placeholder="Enter new igloo name"
          value={newIglooName}
          onChange={(e) => setNewIglooName(e.target.value)}
        />
        <Button variant="primary" onClick={createNewIgloo}>
          Create New Igloo
        </Button>
      </InputGroup>
    </Container>
  );
}

export default Igloos;
